import React, { Component } from 'react';
import {Tab, Tabs} from 'react-mdl';
import styles from './mystyle.module.css';
import ImageZoom from 'react-medium-image-zoom'
import Leerdoel1 from'./docs/Leerdoel1.png';
import APIMethodes from'./docs/APIMethodes.png';
import Leerdoel3 from'./docs/Leerdoel3.png';


class L1 extends Component{
    constructor(props){
        super(props);
        this.state = {activeTab: 0};
    }
    toggleCategories() {
        if(this.state.activeTab === 0){
            return(
                <div><h1 className= {styles.header}>Leerdoel 1</h1>
                <p className= {styles.description}>
                <h2>Leerdoel beschrijving</h2>
                Aan het eind van mijn stageperiode kan ik de container technologie gebruiken voor het deployen{'\n'} 
                van een ASP.NET Core applicatie. Containers technologie is een vrij nieuw technologie die het{'\n'} 
                deployen van systemen eenvoudiger maakt. Tijdens mijn stage zal ik mezelf verdiepen in de{'\n'} 
                technologie. Om dit te doen zal ik de technologie onderzoeken. Vervolgens ga ik de technologie{'\n'} 
                gebruiken voor het deployen van een al bestaande ASP.NET MVC applicatie. Ik heb nu geen{'\n'} 
                ervaring met het gebruiken van Containers technologie. Ik verwacht dat ik aan het einde van mijn{'\n'} 
                stage de Containers commandos beheers en de manier van deployen van een applicatie binnen een{'\n'} 
                container onder de knie krijg.{'\n'}{'\n'}{'\n'}
                <h2>Bewijs van behalen</h2>{'\n'}
                Ik heb mijn doel behaald door het gebruiken van docker als een manier om de REST API die ik had{'\n'} 
                gemaakt te deployen. Ik had docker files gegenereerd voor de API. bovendien had ik docker files{'\n'} 
                gegenereerd voor de messages en de message handlers van NServiceBus. Ook ik had een docker{'\n'} 
                compose gebruikt voor het orchestreren van alle containers die nodig zijn voor de werking van de{'\n'} 
                REST API. Met behulp van docker compose heb ik de werking van drie containers gecombineerd{'\n'} 
                (API container, RabbitMQ, SQL server container). Hieronder is een screenshot van de docker{'\n'} 
                compose file. Sommige lijnen zijn weggehaald voor veiligheidsredenen.{'\n'} 
                {'\n'}

                <div><ImageZoom
                                                    image={{
                                                        src: Leerdoel1,
                                                        alt: 'Leerdoel1',
                                                        className: 'styles.image',
                                                        style: { width: '40em' }
                                                    }}
                                                    zoomImage={{
                                                        src: Leerdoel1,
                                                        alt: '',
                                                        style: {width: '70em'}
                                                    }}
                                                /></div>
                                                {'\n'}{'\n'}
                Ook had ik voor de competentie Advies een onderzoek gedaan naar het gebruiken van de container{'\n'} 
                technologie als een manier van deployen. Het onderzoeksrapport is te zien onder het{'\n'} 
                kopje Advies.{'\n'}
    
                <h3>Persoonlijk opbrengst</h3>
    
                Voordat ik met mijn stage was begonnen had ik geen enkel idee over de container technologie. Ik{'\n'} 
                heb nu genoeg ervaring om een applicatie met docker te deployen. Ik heb de docker commands{'\n'} 
                geleerd door het lezen van de documentatie die op de website van de docker te vinden is. Ook voor{'\n'} 
                het deployen van een .Net Applicatie moest ik onderzoeken in documentatie van ASP.NET{'\n'} 
                Core die te vinden is op website van Microsoft.{'\n'}

    
    
                </p>
                
                
                </div>
               
            )
        } else if(this.state.activeTab === 1){
            return (
                <div><h1 className= {styles.header}>Leerdoel 2</h1>
                <p className= {styles.description}>
                <h2>Leerdoel beschrijving</h2>
                Aan het eind van mijn stageperiode kan ik de moderne methode gebruiken voor het schrijven van{'\n'} 
                een web API. Dit wil ik doen door de REST methode te gebruiken voor het schrijven van de web API{'\n'} 
                die de communicatie tussen de systemen TipTop en RoutIT zal ondersteunen. Ik zal C#{'\n'} 
                programmeertaal gebruiken voor het schrijven van de API. Om mijn leerdoel binnen te halen ga ik{'\n'} 
                de REST methode leren. Vervolgens zal ik die kennis gebruiken om de web API te schrijven. Ik heb{'\n'} 
                mijn leerdoel behaald als ik de Web API werkend krijg volgens de REST methode. Ik zal dit aantonen{'\n'} 
                door middel van een code snippet.{'\n'}{'\n'}{'\n'}
                <h2>Bewijs van behalen</h2>{'\n'}
                Ik heb mijn leerdoel behaald door het implementeren van de API volgens de REST methode. Tijdens{'\n'} 
                mijn stage heb ik gewerkt aan een API die in .NET Core geschreven moest worden. De API{'\n'} 
                moest volgens de REST methode geschreven worden. Onder het kopje Realiseren is te zien hoe ik{'\n'} 
                de standaarden voor het schrijven van een REST API heb gevolgd. Hieronder is een foto van{'\n'} 
                swagger waarin de verbs (get, post, put, Delete) zijn te zien. Het gebruiken van die verbs is een van{'\n'} 
                de meest bekende kenmerken van het schrijven van een REST API.{'\n'} {'\n'}

                <div><ImageZoom
                                                    image={{
                                                        src: APIMethodes,
                                                        alt: 'APIMethodes',
                                                        className: 'styles.image',
                                                        style: { width: '40em' }
                                                    }}
                                                    zoomImage={{
                                                        src: APIMethodes,
                                                        alt: '',
                                                        style: {width: '70em'}
                                                    }}
                                                /></div>
                                                {'\n'}{'\n'}
                
    
                <h3>Persoonlijk opbrengst</h3>
    
                Voordat ik met mijn stage was begonnen had ik weinig ervaring met het schrijven van API’s volgens{'\n'} 
                de REST methode. Daarom had ik vanaf de eerste dag veel gezocht naar informatie en{'\n'} 
                bronnen om die manier van programmeren onder de knie te krijgen. Met behulp van mijn{'\n'} 
                begeleider en het volgen van tutorials die op de website pluralsight te vinden zijn, kan ik nu een API{'\n'} 
                schrijven die aan de beginselen van REST voldoet.{'\n'}

    
    
                </p>
                
                
                </div>
            )
        } else if(this.state.activeTab === 2){
            return (
                <div><h1 className= {styles.header}>Leerdoel 3</h1>
                <p className= {styles.description}>
                <h2>Leerdoel beschrijving</h2>
                Aan het einde van mijn stageperiode kan ik op een zakelijke manier mijn designs aan klanten{'\n'} 
                presenteren. Dit wil ik doen door het presenteren van mijn designs tijdens de meetings met KPN en{'\n'} 
                RoutIT. Om mijn leerdoel te halen zal ik mezelf voorbereiden voor de presentatie door het{'\n'} 
                bespreken van mijn designs met mijn begeleider. Ook zal ik feedback op mijn presentatie vragen.{'\n'} 
                Vervolgens zal ik de feedback met mijn reflectie daarop in een document verwerken. Ik heb mijn{'\n'} 
                leerdoel gehaald als ik mijn designs aan de hand van een presentatie kan uitleggen.{'\n'}{'\n'}{'\n'}
                <h2>Bewijs van behalen</h2>{'\n'}
                Ik heb mijn leerdoel behaald door het presenteren van mijn designs voor de REST API aan KPN en{'\n'} 
                RoutIT. Ik ben meerdere keren bij KPN langs geweest om mijn designs aan hun te presenteren.{'\n'} 
                Hiervoor moest ik altijd een introductiekaart bij me houden. Hieronder is een foto van een van die{'\n'} 
                kaart. Meer informatie over de designs is te vinden onder het kopje analyse 1. Ik heb altijd{'\n'} 
                gepresenteerd samen met Richard en we waren altijd tevreden over de manier waarop we onze{'\n'} 
                ideeën aan KPN hebben kunnen geven.{'\n'} {'\n'}

                <div><ImageZoom
                                                    image={{
                                                        src: Leerdoel3,
                                                        alt: 'Leerdoel3',
                                                        className: 'styles.image',
                                                        style: { width: '40em' }
                                                    }}
                                                    zoomImage={{
                                                        src: Leerdoel3,
                                                        alt: '',
                                                        style: {width: '70em'}
                                                    }}
                                                /></div>
                                                {'\n'}{'\n'}
                
    
                <h3>Persoonlijk opbrengst</h3>
    
                Voordat ik met mijn stage begon had ik wel een beetje ervaring met het presenteren van mijn{'\n'} 
                designs. Deze ervaring heb ik tijdens mijn studie opgedaan, o.a. door het presenteren van mijn{'\n'} 
                designs op school. Toch was het moeilijker om mijn designs voor een groot bedrijf te presenteren.{'\n'} 
                Daarom had ik me goed voorbereid voor het presenteren. Ook had ik begeleiding om nieuwe{'\n'} 
                manieren te gebruiken voor het presenteren van mijn designs. Het leren en gebruiken van swagger{'\n'} 
                voor het presenteren van mijn designs was een hele grote toevoeging voor mijn “presenteren skill”.{'\n'}



    
    
                </p>
                
                
                </div>
            )
        }
    }
    render(){
        return(
            <div classname="Leerdoelen-tabs">
                <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId})} ripple>
                    <Tab>Leerdoel 1</Tab>
                    <Tab>Leerdoel 2</Tab>
                    <Tab>Leerdoel 3</Tab>
                </Tabs>
                <section className="Leerdoel-grid">
                    
                    {this.toggleCategories()}
                </section>
            </div>
        )
    }
}
export default L1;