import React, { Component } from 'react';
import { Tabs, Tab, Navigation} from 'react-mdl';
import { NavLink ,Route, Link} from 'react-router-dom';
import styles from './mystyle.module.css';


class An1 extends Component{
    constructor(props){
        super(props);
        this.state = {activeTab: 0};
    }
    toggleCategories() {
        if(this.state.activeTab === 0){
            return(
                <div>
                <h1 className= {styles.header}>
                    Analyse 1
                </h1>
                <p className= {styles.description}>
                    <h2>Competentie beschrijven</h2>
                    Een requirementsanalyse is het overwegen en bepalen van de requirements voor het ontwikkelen{'\n'} 
                    van een nieuw product, of wijzigingen op een bestaand product, in dit geval op het gebied van{'\n'} 
                    software engineering. Hierbij moet rekening gehouden worden met de (mogelijk conflicterende){'\n'} 
                    vereisten van alle betrokken stakeholders. Deze analyse wordt vaak als essentieel beschouwd in het
                    {'\n'}
                    succesvol implementeren en uitvoeren van een ontwikkelingstraject.{'\n'} 
                    {'\n'}
                    <h2>Bewijs</h2>{'\n'}
                    Alvorens ik kan beginnen met het schrijven van code, moet er voor elke sprint een{'\n'} 
                    requirementsanalyse gedaan worden. Hieronder geef ik voor elke sprints de requirements weer,{'\n'} 
                    samen met een beschrijving van de redenering die achter de requirements zitten.{'\n'}{'\n'}
                    <h3>Requirements Analyse</h3>
                    Voor de opdracht bij FourICT wordt er in sprints gewerkt op basis van een agile manier van werken.
                    {'\n'}
                    Elke sprint is een vaste tijdsperiode, met daarin het doel een aantal stukjes ontwikkeling die aan{'\n'} 
                    het einde van de sprint af moeten zijn. Deze doelen bestaan in feite uit de requirements. Een{'\n'} 
                    requirement is een bepaling van wat het product moet doen. Dit geeft bijvoorbeeld informatie over
                    {'\n'}
                    de benodigde capaciteiten, kwaliteiten, attributen of karakteristieken, die uiteindelijk bruikbaar zijn
                    {'\n'}
                    en meerwaarde bieden voor de eindgebruiker. Deze requirements moeten testbaar zijn, en voldoen
                    {'\n'}
                    aan de bedrijfsbehoefte. Vaak gebeurt het opstellen van de requirements in drie type activiteiten:{'\n'}
                    Communicatie met stakeholders om behoeften en vereisten boven tafel te krijgen{'\n'}
                    Analyse om onvolkomenheden te corrigeren{'\n'}
                    Validatie en documentatie om de requirements vast te leggen{'\n'}{'\n'}

                    Omdat de opdracht bestaat uit het vernieuwen van een al bestaande interface, moest ik deze{'\n'} 
                    interface in eerste instantie bestuderen en op basis daarvan de requirements voor de nieuwe REST{'\n'} 
                    API afleiden.{'\n'}
                    {'\n'}{'\n'}

                    <h3>Requirements voor sprint 1</h3> 
                    Voor de eerste sprint waren de requirements voornamelijk gefoucst op het leren van REST en{'\n'} 
                    Asp.net core. De reden hiervoor was het feit dat de opdracht voor het ontwikkelen van de API{'\n'} 
                    moest voldoen aan de REST principles. Dit heeft geresulteerd in de volgende requirements:{'\n'}
                    {'\n'}

                    <li>REST principles leren aan de hand van tutorial op pluralsight</li>
                    <li>Setup van een omgeving voor het developen met .net core 2.2</li>
                    <li>Opzet maken voor een eigen voorbeeld REST API voor trainingsdoeleinden</li>
                    <li>Tutorial volgen over containerising of een asp.net core applicatie</li>{'\n'}{'\n'}
                    
                    <h3>Requirements voor sprint 2</h3>
                    Voordat ik met sprint 2 begon, had ik toegang gekregen naar de source code van TIPTOP, de{'\n'} 
                    applicatie waar de opdracht voor KPN op gericht was. De requirements voor deze sprint zijn{'\n'} 
                    daarom op deze source code gefocust. Daarnaast is mij gevraagd gebruik te maken van de{'\n'} 
                    NServiceBus als servicemiddel tussen TIPTOP en de gebruikerspartijen. Bovendien was de wens om
                    {'\n'}
                    design van de API in de design tool Swagger te maken, waar ik mij eerst in moest verdiepen. 
                    {'\n'}{'\n'}
                    <li>Source Code van TIPTOP bestuderen.</li>
                    <li>NServiceBus tutorial volgen</li>
                    <li>.net core API project opzitten binnen de TIPTOP repository</li>
                    <li>NServiceBus configureren binnen de API</li>
                    <li>Swagger leren </li>
                    <li>Design maken van de API using Swagger</li>
                    <li>Swagger configureren binnen de API</li>{'\n'}{'\n'}
                    
                    <h3>Requirements voor sprint 3</h3>
                    De requirements voor sprint 3 waren opgezet met de focus op het vernieuwen van de oude{'\n'} 
                    interface naar een REST API die gebruik maakt van de NServiceBus. Om dit te realiseren moest ik de
                    {'\n'}
                    NServiceBus configureren, classes voor zowel de messages als handlers programmeren, en{'\n'} 
                    feedback van mijn begeleider verwerken voor de versioning van de API.{'\n'} 
                    {'\n'}

                    <li>CreateIPAssignment en MigrateIPAssignment message en messagesHandlers</li>
                    <li>Callback messages voor de Create en Migrate IPAssignment maken</li>
                    <li>Messages Classes voor de Assignemnts toevoegen aan de Classes library</li>
                    <li>Transport voor de NServiceBus configureren voor RabbitMQ</li>
                    <li>Versioning toevoegen aan de REST API</li>
                    <li>Recoverbility NServiceBus configureren</li>{'\n'}{'\n'}

                    <h3>Requirements voor sprint 4</h3>
                    In sprint 4 waren de requirements waren gebaseerd op de feedback van mijn begeleider over de{'\n'} 
                    manier waarop de API de opgetreden errors behandelt. Aan de hand van zijn feedback moest ik{'\n'} 
                    nieuwe modellen voor de errors maken. Vervolgens moest ik dit integreren binnen de API.{'\n'}
                    {'\n'} 

                    <li>Orienteren op de nieuwe eigenschappen van .net core 3.0</li>
                    <li>Callback documentatie opzitten en naar RoutIT sturen</li>
                    <li>Costumized BadRequest message implemeteren binnen de API</li>
                    <li>Costumised UnAustherized message implementeren </li>
                    <li>Costumised Internal server Error message implementeren</li>
                    <li>API key based Authentication Toevoegen aan de API</li>{'\n'}{'\n'}

                    <h3>Requirements voor sprint 5</h3>
                    In de vijfde sprint waren de requirements gericht op de communicatie tussen de message handlers{'\n'} 
                    en TIPTOP. Hiervoor heb ik eerst de communicatiemethode van de oude interface bestudeerd.{'\n'} 
                    Vanwege een vertraging in een geplande afspraak met een TIPTOP ontwikkelaar heb ik extra{'\n'} 
                    requirements toegevoegd om de service control van NServiceBus beter in de API te configureren.{'\n'}
                    {'\n'}
                    <li>Unautherised model bug fix</li>
                    <li>Connction manier met de database onderzoeken.</li>
                    <li>NServiceBus Sagas onderzoeken </li>
                    <li>Monitoring Plug in toevoegen aan de NServiceBus configuratie </li>
                    <li>De API isoleren van de TIPTOP repo en op private Github Repo zitten</li>

                    <h3>Requirements voor sprint 6</h3>
                    Sprint 6 was gepasseerd aan het realiseren van de connectie tussen de message{'\n'} 
                    handlers en TIPTOP applicatie. TIPTOP biedt een webservice waarvan ik gebruik kan{'\n'} 
                    maken voor het uitvoeren van de functies van API. om dit te doen het is beter om dit{'\n'} 
                    met interface te doen zodat het geïsoleerd kan worden van applicatie. Verder dit sprint{'\n'}
                    wordt ook op de Sql server voor de Migratie functie gewerkt.{'\n'}
                    {'\n'}
                    <li>De interface naar de webservice van TIPTOP klaar zitten</li>
                    <li>Call naar de webservice in message handler verwerken </li>
                    <li>Test implementatie van de webservice opzitten </li>
                    <li>NServiceBus configuratie onderzoeken  </li>
                    <li>Sql server in een container opzitten en configrureren om buiten de container de data te bewaren. </li>
                    <h3>Requirements voor sprint 7</h3>
                    In sprint 7 waren de requirements gericht op het aan het connecten van de Sql server{'\n'} 
                    container met de messages handler, bovendien de paths naar de web services als{'\n'} 
                    environment parameters te zitten. Verder zijn de rest van de requirement gericht aan{'\n'} 
                    het klaarzetten van docker containers voor de deployment.{'\n'}
                    {'\n'}
                    <li>Connectie tussen Applicatie en Sql server cotainer opzitten</li>
                    <li>Sql server configureren zodat het door docker compose gestart kan worden </li>
                    <li>Paths naar de webservices als environment parameter opzitten</li>
                    <li>Webservice voor de updateAdministrativecontact kopplen </li>
                    <li>Test implementatie voor de Update service opzitten</li>
                    <li>Containers klaar zitten voor de deployment </li>
                    <li> Deployeable contaiers op andere laptop testen</li>
                    
                    <h3>Requirements voor sprint 8</h3>
                    Sprint 8 was een kort sprint in verband met de kerstvakantie de requirement van die{'\n'} 
                    sprint is gericht aan het maken van een testproject voor unit test.{'\n'}  
                    {'\n'}
                    <li>Test project voor de API opzitten</li>
                    <li>Test controller maken voor het testen van de API's controller </li>
                    <h3>Requirements voor sprint 9</h3>
                    Sprint 9 is gericht aan het testen van de system. Eerste moet de API controller getest{'\n'} 
                    worden, daarna worden de functionaliteit van NServiceBus getest. Omdat er is nog{'\n'} 
                    niet nieuws van KPN over de deployment en de connectie met de TIPTOP applicatie{'\n'} 
                    op hun server, kunnen de webservices connectie niet getest worden. Daardoor gebruik{'\n'}
                    ik de test implementatie van de interfaces voor testdoeleinden.{'\n'}  
                    {'\n'}
                    <li>Test voor de NServiceBus message handler opzitten </li>
                    <li>Test plan schrijven en uitvoeren aan de hand van handmatige test en Automated test</li>

                    {'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}
                </p>
                </div>
            )
        } else if(this.state.activeTab === 1){
            return (
                <div><h1 className= {styles.header}>Analyse 2</h1>
            <p className= {styles.description}>
            <h2>Competentie beschrijven</h2>
            Om de correcte werking van software te garanderen is uitgebreid testen van groot. Als een{'\n'} 
            developer moet ik mijn software zorgvuldig testen. Deze competentie stelt voor dat ik een{'\n'} 
            acceptatietest kan opstellen. Omdat het project zich nog niet in de testfase bevindt binnen KPN,{'\n'} 
            stel ik hier een testplan samen voor de gerealiseerde software die ik tijdens mijn stage heb{'\n'} 
            gemaakt.{'\n'} {'\n'}

            <h3>Inleiding</h3>

            Dit document is geschreven om aan te geven wat de tools, test cases, het verwachte resultaat en{'\n'} 
            eventuele verdere tests die gedaan kunnen worden. Op basis van dit document kan de REST API{'\n'} 
            getest worden. Met de bevindingen die gemaakt zullen worden na het uitvoeren van dit test zal{'\n'} 
            een test rapport aan bod komen. Bovendien kunnen de eventuele bugs naar behoren gefixt{'\n'} 
            worden.{'\n'}



            <h3>Inhoudsopgave</h3>{'\n'}

            <li>1.Tools</li>
            <li>2.Test Cases</li>
            <li>3.Tools toepassen</li>
            <li>4.Verwacht resultaat</li>
            <li>5.Verdere tests</li>



            <h3>Tools</h3>{'\n'}
            De API en de bijbehorende models zijn allemaal backend onderdeel van de TIPTOP applicatie.{'\n'} 
            Daarom wordt de API en de andere onderdelen getest met unit test. Voor de unit test worden er{'\n'} 
            van twee nuget packages gebruikt gemaakt namelijk NUnit, en NServiceBus Testing package.{'\n'}


            <h4>1.NUnit</h4>{'\n'}
            Nunit is een framework dat gebruikt kan worden om unit test te schrijven. Een unit test die in dit{'\n'} 
            framework geschreven is kan de werking van een .NET CORE applicatie testen. Het framework geeft{'\n'}
            de mogelijkheid om de functie van een API aan te roepen met een voorbeeld van de input. Verder{'\n'} 
            wordt de output opgepakt de framework ook en geschikt voor de juistheid.{'\n'}



            <h4>2.NServiceBus Testing</h4>{'\n'}
            Met de NServiceBus testing package wordt de functionaliteit van de messages handlers getest. Ook{'\n'}
            message sessions kunnen getest worden. Door het maken van een test message session kan het{'\n'} 
            hele process van het handelen van een message getest worden en wordt het resultaat gecheckt{'\n'} 
            voor de juistheid. Deze package moet gecombineerd worden met NUnit of XUnit. Omdat ik al NUnit{'\n'} 
            had gebruikt zal ik hem ook hiermee combineren.{'\n'} 



            {'\n'}
            <h3>Test Cases</h3>{'\n'}
            
            <h4>1.NUnit</h4>{'\n'}
            <li>1.CreateIPAssignment(*) accepteert een voorbeeld CreateIPAssignment(*) opdracht</li>
            <li>2.De parameters van de opdracht worden aan de hand van de model gevalideerd.</li>
            <li>3.Het gestuurde model naar de middelware is van het type CreateIPAssignment(*) messages.</li> 
            De drie cases worden ook getest voor de functies: DeleteIPAssignment, ReadIPAssignment,{'\n'} 
            UpdateAministrativeContact, en MigrateIPAssignment. De naam van de functie wordt altijd{'\n'} 
            vervangen met de naam van de geteste functie. Toch wordt voor elke functie een aparte test{'\n'} 
            geschreven.{'\n'} 



            <h4>2.NServiceBus Testing</h4>{'\n'}
            <li>1.Een message wordt gestuurd van de API controller. Verder wordt er gecheckt voor het{'\n'} 
            ontvangen van de message met het juist type.</li>
            <li>2.Een handler wordt ingeschakeld met het juiste type message en de door de handler{'\n'} 
            gepubliceerde message type wordt gecontroleerd. </li> 



            {'\n'}


            <h3>Tools toepassing</h3> {'\n'}
            {'\n'}
            Voor beide packages wordt dezelfde techniek gebruikt om de test uit te voeren. Er wordt getest{'\n'} 
            met AAA techniek. AAA staat voor (Arrange, Act, Assert) Arrange In dit gedeelte van de unit test{'\n'} 
            wordt de functie die getest moeten worden geïnitialiseerd met een voorbeeld input. Act Hier wordt{'\n'} 
            de functie aangeroepen. Assert In dit gedeelte wordt de output van de test gecontroleerd.{'\n'} 
 

            <h3>Verwachte resultaat</h3>{'\n'}
            {'\n'}
            Er wordt bij NUnit test getest of alle models de juiste types hebben voor en na het versturen van de{'\n'} 
            messages. Ook wordt er verwacht dat de gestuurde messages worden gecontroleerd met het{'\n'} 
            model. Voor NServiceBus Testing wordt verwacht dat de messages de serviceBus in kunnen.{'\n'} 
            Bovendien wordt er verwacht dat de handlers de juiste message type gaan publiceren.{'\n'} {'\n'}

            <h3>Verdere test</h3>{'\n'}
            {'\n'}
            Aangezien dat de API nog gekoppeld moet worden met de TIPTOP applicatie die op server van KPN{'\n'} 
            draait moet er nog een test komen voor de connectie en de types van de data die uitgewisseld{'\n'} 
            moeten worden.{'\n'}  
            {'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}









            </p>
            
            
            </div>
            ) 
        }
    }
    render(){
        return(
            <div classname="Analyse-tabs">
                <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId})} ripple>
                    <Tab>Analyse1</Tab>
                    <Tab>Analyse2</Tab>
                </Tabs>
                <section className="Analyse-grid">
                    
                    {this.toggleCategories()}
                </section>
            </div>
        )
    }
}
export default An1;