import React, { Component } from 'react';
import { Tabs, Tab, Navigation} from 'react-mdl';
import { Link } from 'react-router-dom';
import stageplan1 from './docs/Stageplan1.pdf';
import stageplan2 from './docs/Stageplan2.pdf';
import styles from './mystyle.module.css';


class Stageplan extends Component{
    constructor(props){
        super(props);
        this.state = {activeTab: 0};
    }
    toggleCategories() {
        if(this.state.activeTab === 0){
            return(
                <div>
               <div><h1 className= {styles.header}>Stageplan versie1</h1></div>
               <p className= {styles.description} >Voorafgaand aan mijn stage heb ik een stageplan geschreven, waarin de volgende onderwerpen {'\n'}
               zijn verwerkt:{'\n'}
               - Bedrijfsorientatie{'\n'}
               - Leerdoelen {'\n'}
               - StageOpdracht{'\n'}
               - Competenties{'\n'} </p>
               <tx className= {styles.description} >Onder de volgende link is het volledige stageplan te vinden:</tx>
               <a className= {styles.link} target="_blank" href={stageplan1}>Stageplan 1</a>
               </div>
            )
        } else if(this.state.activeTab === 1){
            return (
                <div>
                <div><h1 className= {styles.header}>Stageplan versie2</h1></div>
                <p className={styles.description}>De gekregen feedback op de eerste versie heb ik vervolgens verwerkt.{'\n'}
                     Hieruit is een tweede en laatste versie ontstaan.</p>
                <tx className= {styles.description}>De tweede versie is beschikbaar onder de link:</tx>
               <a className= {styles.link}target="_blank" href={stageplan2}>Stageplan 2</a>
               </div>
            )
        }
    }
    render(){
        return(
            <div classname="Stageplan-tabs">
                <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId})} ripple>
                    <Tab>Stageplan1</Tab>
                    <Tab>Stageplan2</Tab>
                </Tabs>
                <section className="Stageplan-grid">
                    
                    {this.toggleCategories()}
                </section>
            </div>
        )
    }
}
export default Stageplan;