import React, { Component } from 'react';
import styles from './mystyle.module.css';
import Container1 from'./docs/Container1.png';
import Container2 from'./docs/Container2.png';
import ImageZoom from 'react-medium-image-zoom';

class Ad1 extends Component{
    render(){
        return(
            <div><h1 className= {styles.header}>Advies</h1>
            <p className= {styles.description}>
            <h2>Competentie beschrijven</h2>
            Bij een advies is het belangrijk dat je een praktijkprobleem kunt ontleden, en hier een aanbeveling{'\n'}
            op kunt doen. Hierbij is het van belang om een heldere probleemomschrijving te geven en{'\n'}
            onderbouwende argumenten aan te leveren waarmee de keuze goed uitgelegd wordt. Daarnaast is{'\n'}
            het gebruik van betrouwbare informatiebronnen ook belangrijk.{'\n'}{'\n'}{'\n'}
            <h2>Bewijs</h2>{'\n'}
            Om het bedrijf van een helder beeld over het gebruik van de container technologie te voorzien,{'\n'}
            heb ik een deskonderzoek gedaan waarover ik een adviesrapport heb geschreven. Dit rapport{'\n'}
            wordt hieronder getoond.{'\n'}{'\n'}

            <h3>Inleiding</h3>

            Dit document dient als adviesrapport rondom het gebruiken van de container technologie{'\n'}
            voor het deploying van een asp.net core api. Het adviesrapport is opgedeeld in twee{'\n'}


            <h4>onderwerpen:</h4>{'\n'}

            1. Wat zijn de beschikbare tools voor het containerisering van een asp.net core{'\n'}
            applicatie.{'\n'}
            2. Wat zijn de voor en nadelen voor het gebruiken van de meeste twee gebruikte{'\n'}
            containerisering technologie.{'\n'}
            {'\n'}
            Als conclusie voor het rapport geef ik een advies aan het bedrijf over het containeriseren van{'\n'}
            de REST API die ik heb gemaakt.



            <h3>Inhoudsopgave</h3>{'\n'}
            1. Containerizing technologie{'\n'}
            2. Containerizing tools {'\n'}
            3. Docker {'\n'}
            4. Voor en nadelen{'\n'} 
            5. Advies  {'\n'}


            <h3>Containerizing technologie</h3>{'\n'}
            Er zijn nog steeds veel ontwikkelingsteams die problemen ondervinden bij en tijdens het deploying{'\n'}
            van applicaties. Met andere woorden, elke developer kent de moeilijkheden van het overbrengen{'\n'}
            van een applicatie van de ene machine naar de andere. Dit kan verschillende oorzaken hebben,{'\n'} 
            zoals:{'\n'}
            Het verschil tussen operating systems tussen ontwikkel en test/productie machine/server{'\n'}
            De rol van de versie van de packages en configuratie in het deploymentproces{'\n'}
            Voorheen werden deze problemen vaak getackeld met een Virtual Machine (VM). Een VM deelt{'\n'} 
            fysieke hardware resources, zonder dat eindgebruikers verandering ervaren in het gebruik van de{'\n'} 
            applicatie. De voordelen van VM zijn dat er efficiënter gebruik van de hardware gemaakt kan{'\n'} 
            worden, waardoor de vraag daalt en er dus minder onderhoud gedaan hoeft te worden. Dit kan{'\n'} 
            zorgen voor een daling in energieverbruik en dus kostenbesparing. Daarnaast maken VM’s gebruik{'\n'} 
            van software die op de server geïnstalleerd zijn, waardoor versiebeheer alleen op serverniveau{'\n'} 
            geüpdatet hoeft te worden. Naast de eerder genoemde problemen bij deployment zijn echter ook{'\n'} 
            andere nadelen aan VM’s. Zo hebben ze meer verwerkings- en opslagcapaciteit en bandbreedte{'\n'} 
            nodig dan een meer traditionele server in het geval van meerdere VM’s die gehost moeten worden.
            {'\n'} 
            Daarnaast kan de stabiliteit een risico vormen als meerdere VM’s veel van de hardware vragen.{'\n'} 
            VM’s kunnen zeker bruikbaar zijn, maar het is niet de optimale oplossing. Hier komt de container{'\n'} 
            technologie om de hoek kijken. Maar wat is een container en waarin kan dit helpen om het proces{'\n'} 
            van deployment te vergemakkelijken?{'\n'}
            Een container kan gezien worden als een verpakkingsmechanisme, waarin een applicatie{'\n'} 
            gescheiden kan worden van de omgeving waarin deze wordt uitgevoerd. Door de{'\n'} 
            “container-applicatie” te ontkoppelen kan deze consistent en eenvoudig worden gebruikt, ongeacht
            {'\n'} 
            de doelomgeving. Een container zorgt voor een scheiding tussen de werkzaamheden. De{'\n'} 
            ontwikkelaar kan zich richten op de logica van de app, terwijl IT teams kunnen richten op de{'\n'} 
            implementatie en het beheer. Ze hoeven hierbij geen rekening te houden met specifieke details{'\n'} 
            zoals app-specifieke configuraties en de softwareversies. Net zoals bij VM kan een container een{'\n'} 
            applicatie samen met de bibliotheken en afhankelijkheden verpakken, wat resulteert in een{'\n'} 
            geïsoleerde omgeving. Hier houden echter de overeenkomsten vrijwel op. Containers werken een{'\n'} 
            stuk eenvoudiger, met vele voordelen tot gevolg.{'\n'} 
            {'\n'}
            Bij VM’s wordt de hardwares gevisualiseerd, bij een container gebeurt dit op het{'\n'} 
            besturingssysteem niveau. Zo kan de uitvoering van meerdere containers op de kernel van dit{'\n'} 
            besturingssysteem plaatsvinden, waardoor de opzet van containers veel eenvoudiger is. De kernel{'\n'} 
            wordt gedeeld, de containers starten sneller en er wordt maar een fractie van het geheugen{'\n'} 
            gebruikt. Tegenwoordig zijn er verschillende containerindelingen beschikbaar.{'\n'}
            {'\n'}

            <h3>Containerizing Tools</h3>{'\n'}
            Van de verschillende tools die beschikbaar zijn voor gebruik is Docker de meeste bekende en{'\n'} 
            gebruikte technologie. Hieronder zijn de gebruikte vijf technologie naast Docker genoemd:{'\n'}
            1. CoreOS rkt{'\n'}
            2. Mesos Containerizes{'\n'}
            3. LXC linux containers{'\n'}
            4. OpenVZ{'\n'}
            5. Containerd{'\n'}

            {'\n'}


            <h3>Docker</h3> {'\n'}
            {'\n'}
            Als de meeste gebruikte containerisering technologie heeft Docker de grootste community{'\n'}
            tussen de andere technologieën. Het feit dat Docker open source is heeft ervoor gezorgd dat het{'\n'} 
            tot nu toe een groot succes is. Er zit echter geen groot verschil tussen Docker en andere{'\n'} 
            vergelijkbare technologieën. Zo goed als alle functionaliteit die door Docker of andere{'\n'} 
            technologieën wordt aangeboden kan ook binnen de andere technologieën geprogrammeerd{'\n'} 
            worden. Het gebruiksgemak van Docker is tot nu toe de onderscheidende factor geworden.{'\n'} 

                   {'\n'}
            <h3>Voor en nadelen van container technologie</h3>{'\n'}
            {'\n'}
            Containerized deployment wordt beschouwd als een nieuwe optie van deployment naast{'\n'} 
            virtual machines en hard metal server. Daarom het is van belang om de voor- en nadelen{'\n'} 
            van containers in vergelijking met de andere twee manieren van deployment in kaart te{'\n'} 
            brengen.{'\n'}{'\n'}

            <h4>Voordelen</h4>

            <li>Lichtgewicht </li>{'\n'}
            Een container kent een grootheid van minder dan 100MB terwijl een VM meerdere gigabytes{'\n'}
            in beslag nemen. Ook de zijn de resources die nodig zijn voor het runnen van container{'\n'} 
            aanzienlijk klein in vergelijking met VM’s. Dit biedt de mogelijkheid om meerdere{'\n'} 
            containers op dezelfde server te laten runnen.{'\n'}{'\n'}
            <li>Snel app deployment</li>{'\n'}
            Een container vereist over het algemeen de minimale requirements voordat ze deployed kan{'\n'}
            worden, waardoor de snelheid van deploying erg versneld kan worden ten opzichte van VM’s{'\n'}
            en hard metal servers.{'\n'}{'\n'}
            <li>Alles verpakt in één pakket</li>{'\n'}
            De container technologie biedt de mogelijkheid om de app met alle benodigde{'\n'} 
            afhankelijkheden in een autonome container te combineren. Vervolgens kan de{'\n'} 
            container op een andere machine die ook de docker engine heeft gedraaid worden.{'\n'}{'\n'}
            <li>Houdt versiebeheer en component bij</li>{'\n'}
            Door het gebruiken van container technologie het is mogelijk om de werkende versie van{'\n'} 
            een container bij te houden en te monitoren. In het geval dat een container na een{'\n'} 
            update niet meer naar behoren werkt, is de schakel naar een oudere versie zeer{'\n'} 
            gemakkelijk in een container omgeving.{'\n'}{'\n'}

            <h4>Nadelen</h4>
            <li>Meerdere container varianten</li>{'\n'}
            Bij het ontwikkelen van een container staat de developer voor de keuze om dit te doen{'\n'} 
            bijvoorbeeld op basis van een LINUX of een Windows container. Als de container{'\n'} 
            gebaseerd wordt op Windows, kan deze niet deployed worden op een LINUX OS, en{'\n'} 
            vice versa. Hier moet dus goed rekening mee gehouden worden bij het design en de{'\n'} 
            opzet van een containerized deployment aanpak.{'\n'}{'\n'}
            <li>Data bewaren </li>{'\n'}
            In het geval dat een container wordt stopgezet, zal alle data die binnen de container{'\n'} 
            aanwezig is weggeschreven worden. Dit zou tot het verlies van data kunnen leiden.{'\n'} 
            Het is mogelijk om de (belangrijke) data ook buiten de container te bewaren, maar dit{'\n'}
            is wel iets waar de developer bewust rekening mee moet houden.{'\n'}{'\n'}

            <li>Veiligheid</li>{'\n'}
            Alle containers die op dezelfde machine draaien gebruiken dezelfde kernel, andere{'\n'} 
            componenten van de host besturingssysteem, en hebben ze allemaal toegang naar{'\n'} 
            de root. Dat betekent dat alle containers bereikt en benaderd kunnen worden vanuit{'\n'} 
            een van de containers. Vanuit een veiligheidsoogpunt zijn VM’s meer geïsoleerd dan{'\n'} 
            containers, wat een overweging kan zijn voor de keuze voor opzet met Virtual{'\n'} 
            Machines.{'\n'}{'\n'}

            <div><ImageZoom
                                                image={{
                                                    src: Container1,
                                                    alt: 'Container1',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: Container1,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
            Dus, daarom geef ik deze cijfers:{'\n'}{'\n'}
            <div><ImageZoom
                                                image={{
                                                    src: Container2,
                                                    alt: 'Container2',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: Container2,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}

            <h3>Advies</h3>
            Het gebruiken van containers als een manier van deployment is een relatief nieuwe{'\n'} 
            technologie en werkwijze. Echter zijn er al veel grote bedrijven die deze aanpak hanteren of{'\n'} 
            vorm aan het geven zijn. Containerized deployment biedt namelijk veel voordelen, die met{'\n'} 
            name op het gebied van efficiëntie het werk van developers binnen het bedrijf enorm kunnen{'\n'}
            verhogen. Hierbij kan vooral gedacht worden aan het snel kunnen schakelen tussen versies,{'\n'}
            bijvoorbeeld bij het testen van een applicatie door het developers team voordat de nieuwe{'\n'} 
            versie wordt aangenomen. Er zijn ook een aantal overwegingen waar altijd rekening mee{'\n'} 
            gehouden moet worden, zoals veiligheid en een keuze voor een Operating System.{'\n'} 
            Mijn advies is om een container-based deployment aan te nemen, omdat de voordelen van{'\n'} 
            snelle deployment en versiebeheer zeker opwegen tegen de eventuele risico’s omtrent{'\n'} 
            bijvoorbeeld veiligheid. Met een goed plan en management kan ook hier een goede invulling{'\n'}
            aan gegeven worden.{'\n'} {'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}{'\n'}









            </p>
            
            
            </div>
        )
    }
}
export default Ad1;