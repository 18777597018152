import React, { Component } from 'react';
import styles from './mystyle.module.css';
import ApiRest from'./docs/ApiRest.png';
import APIMethodes from'./docs/APIMethodes.png';
import POSTAPI from'./docs/POSTAPI.png';
import ymlAPI from'./docs/ymlAPI.png';
import ImageZoom from 'react-medium-image-zoom';
import { Tabs, Tab, Navigation} from 'react-mdl';

class O1 extends Component{
    constructor(props){
        super(props);
        this.state = {activeTab: 0};
    }
    toggleCategories() {
        if(this.state.activeTab === 0){
            return(
            <div>
            <h1 className= {styles.header}>
                Ontwerpen 1
            </h1>
            <p className= {styles.description}>
                <h2>Competentie beschrijven</h2>
                Een ontwerp is een beschrijving van iets bestaands of iets nieuws, vaak in de vorm van een{'\n'} 
                projectie of model. Ontwerpen voor software houdt in dat je in staat bent een ontwerp op te{'\n'} 
                kunnen stellen voor een (deel van een) softwaresysteem, waarbij je gebruik maakt van bestaande{'\n'} 
                componenten en libraries.{'\n'} {'\n'}
                <h2>Bewijs</h2>{'\n'}
                Om de vraag van de opdrachtgever zo goed mogelijk in te vullen met een passend prodcut, zijn{'\n'} 
                zorgvuldige designs van groot belang. Daarom heb ik veel aandacht besteed aan de designs van de{'\n'} 
                REST API. Hieronder twee voorbeelden van deze designs.{'\n'} {'\n'}
                <h3>Sequance Diagram</h3>
                Dit diagram laat de sequence/volgorde van de uitgewisselde berichten zien. Dit design heeft als{'\n'} 
                basis gezorgd voor de REST API tussen TIPTOP en RoutIT (Irma). FourICT had al documentatie van{'\n'} 
                de oude interface van TIPTOP, die ik ook heb ontvangen. Het leek mij toch een goed initiatief om{'\n'} 
                een UML diagram te maken van het oude design en daarin de toegevoegde functionaliteiten en{'\n'} 
                REST principles  te verwerken en helder aan te geven.{'\n'}  {'\n'} 

                 
                <div><ImageZoom
                                                image={{
                                                    src: ApiRest,
                                                    alt: 'sequanceDia',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: ApiRest,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}

                <h3>Swagger</h3>
                Om de functionaliteit van de API te ontwerpen heb ik een nieuwe tool gebruikt, genaamd OpenAPI{'\n'} 
                of ook wel Swagger. Door middel van een yml bestand kon ik een visuele beschrijving maken van de
                {'\n'}
                functies die de API zal bevatten. Bovendien stelt OpenAPI me in staat een voorbeeld geven van de{'\n'} 
                json berichten die de API ontvangt en verstuurt. Tijdens het implementeren van de REST API kon de
                {'\n'}
                OpenAPI zo geconfigureerd worden dat de designs geupdatet werden met de nieuwste{'\n'} 
                toevoegingen.{'\n'} 
                 {'\n'} 
                <div><ImageZoom
                                                image={{
                                                    src: APIMethodes,
                                                    alt: 'API methodes',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: APIMethodes,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: POSTAPI,
                                                    alt: 'Post mehtode',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: POSTAPI,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: ymlAPI,
                                                    alt: 'yml voorbeeld',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: ymlAPI,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}




                De validatie van de designs staat onder de tab ontwerpen 2{'\n'}{'\n'}


            </p>
            </div>
        )} else if(this.state.activeTab === 1){
            return (
                <div><h1 className= {styles.header}>Ontwerpen 2</h1>
                <p className= {styles.description}>
                <h2>Competentie beschrijven</h2>
                Je kunt een acceptatietest opstellen aan de hand van kwaliteitseigenschappen die gelden bij het{'\n'} 
                bedrijf. {'\n'}{'\n'}
                <h2>Bewijs</h2>{'\n'}
                Na het opstellen van de designs moeten die designs gevalideerd worden. Dit moet gedaan worden{'\n'} 
                om te checken of de designs aan alle requirements voldoen.{'\n'}
 

                {'\n'} {'\n'}
                <h3>Sequance Diagram</h3>
                Het sequence diagram is gemaakt aan de hand van de system requirements die hieronder{'\n'} 
                beschreven staan:{'\n'}   {'\n'} 
                <li> Voor alle IP Assignments: Als de gebruiker de call voor de assignment naar de API stuurt, moet{'\n'} 
                een HTTP response naar hem gestuurd worden met daarin de status van zijn request.{'\n'}  
                status van zijn request.</li>{'\n'} 
                <li>Na het ontvangen van de request moet de API de request doorsturen naar de TIPTOP applicatie.</li>{'\n'} 
                <li>Nadat de request is behandeld door TIPTOP wordt een callback vanaf TIPTOP naar de API{'\n'} 
                gestuurd. Deze callback wordt door de API opgepakt en verder gestuurd naar IRMA.{'\n'} </li>{'\n'}
                <li>IRMA moet een HTTP code terugsturen naar de API om het ontvangen van de callback te{'\n'} 
                bevestigen. </li>{'\n'}

                Al deze punten zijn in het sequence diagram terug te zien. Gedurende het project zijn{'\n'} 
                deze designs besproken met de betrokken partijen, namelijk met KPN en RoutIT. Na het bespreken{'\n'} 
                van de designs zijn de designs gevalideerd met Richard en Mark voordat ze gebruikt kunnen{'\n'} 
                worden voor het maken van de API.{'\n'}{'\n'}



                 

                <h3>Swagger</h3>
                Het swagger design is gemaakt om de system requirements die hieronder opgesomd zijn te{'\n'} 
                realiseren:{'\n'}
 
                <li>De API moet de REST standaarden volgen</li>{'\n'} 
                <li>De oude benaming van de interface moet vervangen worden met REST verbs</li>{'\n'}
                <li>RoutIT moet een voorbeeld krijgen van de JSON berichten die ze naar de API moeten sturen om{'\n'} 
                de functie ervan te gebruiken.</li>{'\n'} 
                <li> RoutIT moet een voorbeeld van de teruggekregen JSON files hebben zodat ze hun kant van het{'\n'}
                systeem kunnen opbouwen.</li>{'\n'}{'\n'} 
                Deze requirement is terug te zien in het design dat met swagger{'\n'} 
                gemaakt is. De designs worden gevalideerd met Richard en Mark. Daarnaast worden de designs{'\n'} 
                ook met KPN besproken en gevalideerd.{'\n'}{'\n'}{'\n'}


            </p>
            </div>
            )
        }
    }
    render(){
        return(
            <div classname="Ontwerp-tabs">
                <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId})} ripple>
                    <Tab>Ontwerpen 1</Tab>
                    <Tab>Ontwerpen 2</Tab>
                </Tabs>
                <section className="Ontwerp-grid">
                    
                    {this.toggleCategories()}
                </section>
            </div>
        )
    }
}
export default O1;