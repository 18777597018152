import React, {Component} from 'react';
import './App.css';
import {Layout, Header, Navigation, Drawer, Content} from 'react-mdl';
import Main from'./comptenties/main';
import { Link } from 'react-router-dom';
import linkedin from './comptenties/docs/Linkedin.png';


class App extends Component {
  render() {
    return (
      <div className="demo-big-content">
    <Layout>
        <Header title="Portfolio" scroll>
            <Navigation>
                <Link to="/">FourICT</Link>
                <Link to="/Stageplan">stageplan</Link>
                <Link to="/Advies">Advies</Link>
                <Link to="/Analyse1">Analyse</Link>
                <Link to="/Beheer">Beheer</Link>
                <Link to="/Leerdoel1">Leerdoelen</Link>
                <Link to="/Ontwerp1">Ontwerpen</Link>
                <Link to="/Realise1">Realiseren</Link>
                <Link to="/Skills1">Skills</Link>
                
            </Navigation>
        </Header>
        <Drawer title="Content">
            <Navigation>
                <Link to="/">FourICT</Link>
                <Link to="/Stageplan">stageplan</Link>
                <Link to="/Advies">Advies</Link>
                <Link to="/Analyse1">Analyse</Link>
                <Link to="/Beheer">Beheer</Link>
                <Link to="/Leerdoel1">Leerdoelen</Link>
                <Link to="/Ontwerp1">Ontwerpen</Link>
                <Link to="/Realise1">Realiseren</Link>
                <Link to="/Skills1">Skills</Link>
                
            </Navigation>
        </Drawer>
        <Content>
            <div className="page-content" />
            <Main></Main>
            
        </Content>
        <div className="footer">
            <a href="https://www.linkedin.com/in/malaz-esber-9489b2121/" target="blank"><img className="footer-image" src={linkedin} /></a>
          </div>


        
    </Layout>
    
</div>
    );
  }
}
export default App;
