import React, { Component } from 'react';
import {Tab,Tabs} from 'react-mdl';
import styles from './mystyle.module.css';
import ImageZoom from 'react-medium-image-zoom'
import Skills1 from'./docs/Skills1.png';
import Feedbackformulier from './docs/Feedbackformulier.pdf';
import Begeleider from './docs/StagebegeleiderFeedback.pdf';

class S1 extends Component{
    constructor(props){
        super(props);
        this.state = {activeTab: 0};
    }
    toggleCategories() {
        if(this.state.activeTab === 0){
            return(
                <div><h1 className= {styles.header}>Skills 1</h1>
                <p className= {styles.description}>
                <h2>Competentiebeschrijving</h2>
                Je kunt je inwerken en aanpassen aan het stagebedrijf betreft de werkwijze, regels en procedures{'\n'} 
                en bent daarnaast in staat om hierop en op je werkzaamheden te reflecteren.{'\n'}{'\n'}{'\n'}
                
                <h2>Bewijs</h2>{'\n'}
                Om deze competentie te bewijzen heb ik een feedbackformulier gemaakt. Het feedbackformulier{'\n'} 
                bevat groepen van vragen om de feedback van mijn stagebegeleider op alle competenties te{'\n'} 
                vergaren. In het formulier vermeld ik eerst mijn feedback die ik aan mezelf geeft. Dit wordt{'\n'} 
                vervolgd met de feedback van mijn stagebegeleider. Deze manier van feedback geven werd erg{'\n'} 
                gewaardeerd door mijn begeleider. Hieronder is een voorbeeld van het formulier.{'\n'}  {'\n'}

                <div><ImageZoom
                                                    image={{
                                                        src: Skills1,
                                                        alt: 'Skills1',
                                                        className: 'styles.image',
                                                        style: { width: '40em' }
                                                    }}
                                                    zoomImage={{
                                                        src: Skills1,
                                                        alt: '',
                                                        style: {width: '70em'}
                                                    }}
                                                /></div>
                                                {'\n'}{'\n'}
                Om het hele formulier te bekijken kan deze link gebruikt worden:{'\n'} 
                <a className= {styles.link} target="_blank" href={Feedbackformulier}>Feedbackformulier Link</a>                                    
                {'\n'}{'\n'}{'\n'}{'\n'}
    
    
                </p>
                
                
                </div>
            )
        } else if(this.state.activeTab === 1){
            return (
                <div><h1 className= {styles.header}>Skills 2</h1>
                <p className= {styles.description}>
                <h2>Competentiebeschrijving</h2>
                Je kunt afgesproken werkzaamheden zelfstandig (inzet, motivatie, respect en dergelijke) uitvoeren{'\n'} 
                en geeft voortgang en eventuele knelpunten proactief aan.{'\n'}{'\n'}{'\n'}
                
                <h2>Bewijs</h2>{'\n'}
                De bewijs voor dit competentie staat verwerkt in de feedbackformulier waarin staat in het{'\n'} 
                algemene gedeelte vragen die bedoeld waren om dit competentie te bewijzen.{'\n'} 
                Het bewijs voor deze competentie staat verwerkt in het algemene gedeelte van het{'\n'} feedbackformulier, waarin de vragen bedoeld zijn om deze competentie te bewijzen.{'\n'} 
                Door op deze link te klikken <a className= {styles.link} target="_blank" href={Feedbackformulier}>Feedbackformulier Link</a> opent het formulier{'\n'}
                Hierbij ook de formulier die op praktijklink is <a className= {styles.link} target="_blank" href={Begeleider}>Feedback van praktijklink</a>
                                    

    
    
                </p>
                
                
                </div>
            )
        }
    }
    render(){
        return(
            <div classname="Skills-tabs">
                <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId})} ripple>
                    <Tab>Skills 1</Tab>
                    <Tab>Skills 2</Tab>
                </Tabs>
                <section className="Skills-grid">
                    
                    {this.toggleCategories()}
                </section>
            </div>
        )
    }
}
export default S1;