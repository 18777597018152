import React, { Component } from 'react';
import Dagboek from './docs/Dagboek.pdf';
import styles from './mystyle.module.css';

class B1 extends Component{
    render(){
        return(
            <div>
               <div><h1 className= {styles.header}>Beheer</h1></div>
               <p className= {styles.description}>
               <h2>Competentiebeschrijving</h2>
               Je kunt werken volgens een vooraf vastgesteld en goedgekeurd stageplan (inclusief planning) en{'\n'} 
               eventuele afwijkingen onderbouwen.{'\n'} 

               <h2>Bewijs</h2>{'\n'}
               Om deze competentie te bewijzen verwijs ik naar mijn stageplan dat te vinden is onder het kopje{'\n'} 
               stageplan. Bovendien heb ik tijdens mijn stage een dagboek bijgehouden over waar ik mee bezig{'\n'} 
               was. Tijdens mijn stage waren de prioriteiten niet consistent en moest ik mijn planning altijd{'\n'} 
               aanpassen zodat ik altijd mijn afspraken op tijd kon inleveren. Aan het begin van elke sprint stel ik{'\n'} 
               een paar requirements die uitgevoerd moeten worden tijdens de sprint. Elke dag noteerde ik wat ik{'\n'} 
               aan het doen was om de requirement van de sprint uitgevoerd te krijgen. Door deze manier van{'\n'} 
               werken te hanteren kon ik makkelijk afwijkingen in mijn planning aanpakken. Via deze link{'\n'} 
               Dagboek kan mijn dagboek bekeken worden. <a className= {styles.link} target="_blank" href={Dagboek}>Dagboek</a>{'\n'}
               De sprint requirements zijn te zien onder{'\n'} 
               het kopje Analyse 1.{'\n'}  {'\n'}{'\n'}
               
               

               </p>
               </div>
               
        )
    }
}
export default B1;