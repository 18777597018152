import React, { Component } from 'react';
import {Tab,Tabs} from 'react-mdl';
import styles from './mystyle.module.css';
import ImageZoom from 'react-medium-image-zoom'
import Csharp1 from'./docs/Realiseren/Csharp1.png';
import Csharp2 from'./docs/Realiseren/Csharp2.png';
import Csharp3 from'./docs/Realiseren/Csharp3.png';
import Csharp4 from'./docs/Realiseren/Csharp4.png';
import Csharp5 from'./docs/Realiseren/Csharp5.png';
import REST1 from'./docs/Realiseren/REST1.png';
import REST2 from'./docs/Realiseren/REST2.png';
import REST3 from'./docs/Realiseren/REST3.png';
import REST4 from'./docs/Realiseren/REST4.png';
import Swagger1 from'./docs/Realiseren/Swagger1.png';
import Swagger2 from'./docs/Realiseren/Swagger2.png';
import NUnit1 from'./docs/Realiseren/Nunittest1.png';
import NUnit2 from'./docs/Realiseren/NunitTest2.png';
import NServiceBusTest1 from'./docs/Realiseren/NServiceBusTest1.png';
import NServiceBusTest2 from'./docs/Realiseren/NServiceBusTest2.png';
import UnitTest from'./docs/Realiseren/UnitTest.png';

class R1 extends Component{constructor(props){
    super(props);
    this.state = {activeTab: 0};
}
toggleCategories() {
    if(this.state.activeTab === 0){
        return(
           <div><h1 className= {styles.header}>
                Realiseren 1
                </h1>
                <p className= {styles.description}>
                  <h2>Competentie beschrijven</h2>
                  De competentie om software te realiseren houdt in dat je conform de opgestelde requirements van
                  {'\n'}
                  de opdracht software ontwikkeld, die voldoet aan de kwaliteitsstandaarden die voor software{'\n'} 
                  engineering binnen het bedrijf gehanteerd worden.{'\n'} 
                  {'\n'}
                  <h2>Bewijs</h2>{'\n'}
                  Omdat ik voor de opdracht de API in asp.net core moest ontwikkelen, was een van de requirements
                  {'\n'}
                  het gebruiken van c#. Daarnaast moest ik rekening houden met de REST standaarden aangezien de{'\n'} 
                  API gebaseerd moest worden op deze standaard. Een derde requirement was het gebruiken van{'\n'} 
                  Swagger voor mijn design, waarin ik ook bepaalde standaarden moest hanteren voor het{'\n'} 
                  commentaar op mijn code. Hieronder heb ik afbeeldingen toegevoegd ter illustratie hiervan.{'\n'}
                  {'\n'}
                  <h3>C# standaarden</h3>
                  Good layout uses formatting to emphasize the structure of your code and to make the{'\n'}
                  code easier to read. Microsoft examples and samples conform to the following{'\n'}
                  conventions:{'\n'}{'\n'}

                  #Write only one declaration per line.{'\n'}

                  <div><ImageZoom
                                                image={{
                                                    src: Csharp1,
                                                    alt: 'Csharp1',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: Csharp1,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                #Use parentheses to make clauses in an expression apparent, as shown in the following code.{'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: Csharp2,
                                                    alt: 'Csharp2',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: Csharp2,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                #Use implicit typing for local variables when the type of the variable is obvious from{'\n'}
                the right side of the assignment, or when the precise type is not important.{'\n'}{'\n'}

                <div><ImageZoom
                                                image={{
                                                    src: Csharp3,
                                                    alt: 'Csharp3',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: Csharp3,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                #Use implicit typing to determine the type of the loop variable in for and foreach loops.{'\n'}
                The following example uses implicit typing in a for statement.{'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: Csharp4,
                                                    alt: 'Csharp4',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: Csharp4,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                #Use the concise form of object instantiation, with implicit typing, as shown in the {'\n'}
                following decalration.{'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: Csharp5,
                                                    alt: 'Csharp5',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: Csharp5,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                  <h3>REST standaarden</h3>{'\n'}{'\n'}
                  HTTP has defined few sets of methods which indicates the type of action to be performed{'\n'}
                  on the resources.{'\n'}
                  The URL is a sentence, where resources are nouns and HTTP methods are {'\n'}
                  verbs. {'\n'}
                  The important HTTP methodes are as follows:{'\n'}
                  1. GET methode requests data from the resources and should not produce any side {'\n'}
                  effect.{'\n'}{'\n'}

                  <div><ImageZoom
                                                image={{
                                                    src: REST1,
                                                    alt: 'REST1',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: REST1,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                2.POST method requests the server to create a resource in the database, mostly {'\n'}
                when a web form is submitted.{'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: REST2,
                                                    alt: 'REST2',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: REST2,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                3.PUT method requests the server to update resource or create the resource, if it {'\n'}
                doesn't exist.{'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: REST3,
                                                    alt: 'REST3',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: REST3,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                4. DELETE method requests that the resources, or its instance, should be removed {'\n'}
                from the database.{'\n'}{'\n'}

                <div><ImageZoom
                                                image={{
                                                    src: REST4,
                                                    alt: 'REST4',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: REST4,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                  <h3>Swagger standaarden</h3>
                  In order to make the packages that auto document your api into swagger word you{'\n'}
                  should switch commenting from two slashes to three also you should use summary{'\n'}
                  to give a description of the method or field. You can also give an example about the{'\n'} 
                  expected json file to receive or to send.{'\n'}{'\n'}
                  <div><ImageZoom
                                                image={{
                                                    src: Swagger1,
                                                    alt: 'Swagger1',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: Swagger1,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: Swagger2,
                                                    alt: 'Swagger2',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: Swagger2,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}

                </p>
        </div>
        )
    } else if(this.state.activeTab === 1){
        return (
            <div><h1 className= {styles.header}>
                Realiseren 2
                </h1>
                <p className= {styles.description}>
                  <h2>Competentie beschrijven</h2>
                  Als een developer moet de student in staat zijn om zijn software te testen zodat de code werking{'\n'} 
                  en stabiliteit gegarandeerd kan worden.{'\n'} 
 
                  {'\n'}
                  <h2>Bewijs</h2>{'\n'}
                  Om deze competentie te bewijzen schrijf ik een testrapport om de resultaten van mijn testplan{'\n'} 
                  uitvoering te laten zien.{'\n'}
                  {'\n'}
                  <h2>Testrapport</h2>
                  <h3>Inleiding</h3>
                  In dit document zullen de resultaten van de gedane tests aan bod komen. De Testmethode is{'\n'} 
                  beschreven in het testplan dat in Analyse 2 te vinden is. Om de testresultaten te laten zien wordt er{'\n'} 
                  gebruikt gemaakt van screenshots. Bovendien zal de tekstuele beschrijving van de screenshots{'\n'} 
                  helpen met het beter begrijpen van de uitgevoerde tests.{'\n'} 
{'\n'}

                  <h3>Inhoudsopgave</h3>
                  <li>1.Testresultaten</li>
                  <li>1.1.NUnit</li>
                  <li>1.1.1Message type check</li>
                  <li>1.1.2Foutieve Model</li>
                  <li>1.1.3Werkende Model</li>
                  <li>1.2.NServiceBus.Testing</li>
                  <li>1.2.1.Gelukt verzending</li>
                  <li>1.2.2.Gelukt publiceren</li>
                  <li>2.Resultaat</li>

                  <h3>1.Testresultaten</h3>

                  <h3>1.1.NUnit </h3>
                  Met de Nuget package NUnit had ik de controller van de API controller getest. Drie verschillende{'\n'} 
                  gevallen waren getest.{'\n'} 


                  <h3>1.1.1.Message type check</h3>
                  Hierin wordt het type van de massage gecontroleerd. De API krijgt als een input een model dat als{'\n'} 
                  JSON file komt. Daarna wordt dit model in een NServiceBus Message gezet zodat het in de queue{'\n'} 
                  gezet kan worden. Deze message heeft een bepaald type en erft een NServiceBus model. Wat{'\n'} 
                  betekent dat het niet gestuurd kan worden als het niet het juiste type is. Daarom wordt het type{'\n'} 
                  ervan met deze unit test altijd gecheckt.{'\n'} {'\n'}

                  <div><ImageZoom
                                                image={{
                                                    src: NUnit1,
                                                    alt: 'NUnit1',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: NUnit1,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}

                Deze code volgt het AAA (Arrange, Act, Assert) principe van testen. Vanaf lijn 63 tot en met lijn 86{'\n'} 
                wordt het Arrange gedeelte gemaakt. Hierin wordt een voorbeeld van de model gemaakt zodat het{'\n'} 
                in de oproep van de functie gebruikt kan worden. In lijn 88 wordt de API functie aangeroepen{'\n'} 
                met de voorbeeld input. In lijn 91 wordt het model van de verstuurde message gecontroleerd.{'\n'} 
                Hiervoor had ik de hele controller voorzien van een generic arrange waarin ik een instance van de{'\n'} 
                controller maak.{'\n'} 

                <h3>1.1.2.Werkende Model</h3>
                De code voor het checken van de message type heeft ook een extra lijnen (89 en 92) om een{'\n'} 
                controle te doen voor de teruggekregen HTTP code met het model dat erbij hoort. Elke API{'\n'} 
                controller functie heeft een check model condition. In het geval dat het model verkeerd is wordt er{'\n'} 
                een andere HTTP code dan degene die bij een OK return type hoort.{'\n'}{'\n'}
                <h3>1.1.3.Foutieve Model</h3>
                Voor het testen van de functies voor het behandelen van een foutief model moet een andere{'\n'} 
                arrange gedaan worden. In deze arrange zal de voorbeeldinput expliciet fouten bevatten zodat het{'\n'} 
                teruggeven van een Bad Request response gecontroleerd kan worden. Hieronder is de code te zien{'\n'} 
                van de Foutieve Model test voor de Read Functie. In lijn 54 is de input een empty string te zien. Dit{'\n'} 
                moet een Bad Request als een response teruggeven. Lijn 56 is een oproep van de functie met de{'\n'} 
                foutieve input. In lijn 58 wordt het terugkrijgen van Bad Request response met een Assert functie{'\n'} 
                gecheckt.{'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: NUnit2,
                                                    alt: 'NUnit2',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: NUnit2,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                
                <h3>1.2.NServiceBus.Testing</h3>
                Met behulp van de nuget package NServiceBus.Testing samen met NUnit package wordt de{'\n'} 
                verzending van de messages naar de queue gecontroleerd. Ook wordt de werking van de handler{'\n'} 
                getest door het checken van de publiceren van de Call Back messages.{'\n'} 


                <h3>1.2.1.Gelukt verzending</h3>
                Voor het testen van NServiceBus wordt er ook van het AAA principle gebruikt gemaakt. In de foto{'\n'} 
                hieronder is de test code te zien. Vanaf lijn 34 tot en met 65 wordt de Arrange gedaan. In de lijn 34{'\n'} 
                is het aanmaken van een testable message session te zien, wat een functie van de Nuget package{'\n'} 
                NServiceBus.Testing is. Daarna wordt de rest van de arrange gedaan. Op lijn 67 wordt de functie{'\n'} 
                aangeroepen (Act). Lijn 69 controleert de queue voor het aanwezigheid van de message. Lijn 70 is{'\n'} 
                bedoeld om te controleren wat de message type die in de queue is.{'\n'} {'\n'}

                <div><ImageZoom
                                                image={{
                                                    src: NServiceBusTest1,
                                                    alt: 'NServiceBusTest1',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: NServiceBusTest1,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}

                <h3>1.2.2.Gelukt publiceren </h3>

                Op dezelfde manier van de Gelukt verzending test wordt het werking van de handler getest.{'\n'} 
                Hieronder is de code van de test te zien. Op lijn 52 wordt de queue gecheckt voor de aanwezigheid{'\n'} 
                van de message. Lijn 53 doet ook de check van de type message die in de queue zit. Deze message{'\n'} 
                heeft een ander type omdat binnen de handler de aanroep van de webservice naar de TIPTOP{'\n'} 
                applicatie wordt gedaan, en de response ervan in een nieuwe type message wordt gezet. Daarom{'\n'} 
                wordt er in deze test eigenlijk ook de werking van de interface naar de webservice gecheckt.{'\n'}{'\n'}
                <div><ImageZoom
                                                image={{
                                                    src: NServiceBusTest2,
                                                    alt: 'NServiceBusTest2',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: NServiceBusTest2,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}
                
                <h3>2.Resultaat</h3>
                Alle tests die gemaakt zijn met behulp van UnitTest kunnen door het gebruiken van Visual Studio{'\n'} 
                met druk op de knop gerund worden. Hieronder is een foto van het resultaat dat Visual Studio{'\n'} 
                teruggeeft na het runnen van de tests.{'\n'}{'\n'}

                  <div><ImageZoom
                                                image={{
                                                    src: UnitTest,
                                                    alt: 'UnitTest',
                                                    className: 'styles.image',
                                                    style: { width: '40em' }
                                                }}
                                                zoomImage={{
                                                    src: UnitTest,
                                                    alt: '',
                                                    style: {width: '70em'}
                                                }}
                                            /></div>
                                            {'\n'}{'\n'}

                </p>
        </div>
        )
    }
}
render(){
    return(
        <div classname="Realiseren-tabs">
            <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId})} ripple>
                <Tab>Realiseren 1</Tab>
                <Tab>Realiseren 2</Tab>
            </Tabs>
            <section className="Realiser-grid">
                
                {this.toggleCategories()}
            </section>
        </div>
    )
}
}
export default R1;