import React from 'react';
import {Switch, Route} from 'react-router-dom';
import F1 from './FourICT';
import Ad1 from './Ad1';
import An1 from './An1';
import B1 from './B1';
import L1 from './L1';
import O1 from './O1';
import R1 from './R1';
import S1 from './S1';
import Stageplan from './Stageplan';



const Main = () => (
    <Switch>
        <Route exact path="/" component={F1} />
        <Route path="/Advies" component={Ad1} />
        <Route path="/Analyse1" component={An1} />
        <Route path="/Beheer" component={B1} />
        <Route path="/Leerdoel1" component={L1} />
        <Route path="/Ontwerp1" component={O1} />
        <Route path="/Realise1" component={R1} />
        <Route path="/Skills1" component={S1} />
        <Route path="/Stageplan" component={Stageplan} />
    </Switch>
)
export default Main;