import React, { Component } from 'react';
import styles from './mystyle.module.css';

class F1 extends Component{
    render(){
        return(
            <div><h1 className= {styles.header}>
                    FourICT
                </h1>
                <p className= {styles.description}>
                Het stagebedrijf heet FourICT en is mede opgericht door Henk van Ewijk en Richard van Leeuwe, de{'\n'}

                huidige directeurs. Het bedrijf houdt zich bezig met ICT oplossingen zoals procesautomatisering en {'\n'}

                software ontwikkeling voor een verschillende sectoren zoals telecom, media en healthcare.{'\n'}{'\n'}

                FourICT ondersteunt organisaties bij het automatiseren en optimaliseren van hun bedrijfsprossen {'\n'}

                met behulp van AuraPortal iBPMS, wat staat voor intelligent Business Process Management Suite. {'\n'}

                Naast specialistische kennis van deze tool heeft het bedrijf ook veel technische kennis(integratie), wat {'\n'}

                resulteert in de oplevering van producten met hoge kwaliteit. {'\n'}{'\n'}



                KPN en de Koninklijke Bibliotheek (KB) zijn o.a. twee belangrijke klanten. Voor KPN levert{'\n'}

                het bedrijf ICT oplossingen voor de zakelijke markt van KPN. Aan de andere kant zorgt{'\n'}

                FourICT voor de ontwikkeling van een nieuwe webshop voor de Koninklijke Bibliotheek. Deze{'\n'}

                webshop is een webapplicatie die met behulp van Umbraco CMS is ontwikkeld. Op de webshop {'\n'}

                kunnen de klanten van de KB boeken en diensten kopen of huren.{'\n'}{'\n'}


                FourICT is gevestigd in Zoetermeer en ziet zichzelf als een no-nonsense IT-bedrijf. Ze{'\n'}

                gaan de uitdaging graag aan. Samen met hun klanten werken ze om een succes te{'\n'}

                realiseren. Een succes met aantoonbare resultaat. Bovendien wordt het concept “afspraak{'\n'}

                is afspraak” gehanteerd in de relatie tussen FourICT en haar klanten. Dit zorgt ervoor dat FourICT {'\n'}

                als een betrouwbare partner wordt gezien. {'\n'}{'\n'}


                Momenteel werken er tien medewerkers op locatie, als volgt verdeeld:{'\n'}

                2 managing partners{'\n'}

                4 AuraPortal consultants{'\n'}

                1 junior developer{'\n'}

                1 senior developer{'\n'}

                1 office manager{'\n'}

                1 afstudeer developer{'\n'}

                Daarnaast heeft FourICT relaties met een aantal ZZP’ers die op oproepbasis actief zijn voor {'\n'}

                verschillende klanten, met name op het gebied van softwareontwikkeling.{'\n'}{'\n'}



                Het bedrijf kent een informeel sfeer, dat komt doordat iedereen op dezelfde plek zit. Ook is het vrij {'\n'}

                gebruikelijk om naar de manager te lopen om iets met hem te bespreken. Iedere maandagochtend {'\n'}

                wordt de voortgang van projecten met iedereen besproken. Dat wordt gedaan door een standup {'\n'}

                meeting te houden waarin iedereen meedoet (Scrum methode).{'\n'}

                Binnen het bedrijf zit ik in dezelfde ruimte als alle medewerkers. Mijn werkplek bevindt zich{'\n'}

                tegenover mijn begeleider Mark Barto. Mark is een senior developer, die al sinds 2012 actief is bij {'\n'}

                FourICT en over een ruime ervaring beschikt. Hij heeft mij goed kunnen ondersteunen bij mijn {'\n'}

                vragen en daar ben ik hem dankbaarvoor. {'\n'}{'\n'}


                </p>
            
            </div>
        )
    }
}
export default F1;